// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-reuters-index-js": () => import("./../../../src/pages/reuters/index.js" /* webpackChunkName: "component---src-pages-reuters-index-js" */),
  "component---src-pages-reuters-install-js": () => import("./../../../src/pages/reuters/install.js" /* webpackChunkName: "component---src-pages-reuters-install-js" */),
  "component---src-pages-reuters-uninstall-js": () => import("./../../../src/pages/reuters/uninstall.js" /* webpackChunkName: "component---src-pages-reuters-uninstall-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-uninstall-js": () => import("./../../../src/pages/uninstall.js" /* webpackChunkName: "component---src-pages-uninstall-js" */),
  "component---src-pages-weatherbug-index-js": () => import("./../../../src/pages/weatherbug/index.js" /* webpackChunkName: "component---src-pages-weatherbug-index-js" */),
  "component---src-pages-weatherbug-install-js": () => import("./../../../src/pages/weatherbug/install.js" /* webpackChunkName: "component---src-pages-weatherbug-install-js" */),
  "component---src-pages-weatherbug-uninstall-js": () => import("./../../../src/pages/weatherbug/uninstall.js" /* webpackChunkName: "component---src-pages-weatherbug-uninstall-js" */)
}

